<template>
  <a-layout class="app-layout">
    <a-layout-sider
      class="layout-sidebar"
      breakpoint="lg"
      :collapsed-width="device.mobile ? 0 : 80"
      v-model:collapsed="appStore.collapsed"
      :trigger="device.mobile ? null : ''"
      collapsible
      theme="dark"
    >
      <div class="logo">
        <img
          v-if="!appStore.collapsed"
          :src="require('/src/assets/logo/logo.svg')"
          alt="logo-img"
          :style="{ height: '55px', width: '90%' }"
        >
        <img
          v-else
          :src="require('/src/assets/logo/logo--circle.png')"
          alt="logo-img"
          :style="{ height: '40px', width: '40px' }"
        >
      </div>

      <a-menu
        class="sidebar-menu"
        v-model:selectedKeys="selectedKeys"
        mode="vertical"
        theme="dark"
      >
        <!-- Role: Admin -->
        <template v-if="authStore.info.role == 4">
          <a-menu-item class="menu-item" key="AppMembers" @click="toPage('AppMembers')">
            <Icon>
              <template #component>
                <span class="mdi mdi-account-outline" :style="menuItemStyle" />
              </template>
            </Icon>
            <span class="item-text">會員管理</span>
          </a-menu-item>

          <a-menu-item class="menu-item" key="AppAssistants" @click="toPage('AppAssistants')">
            <Icon>
              <template #component>
                <span class="mdi mdi-account-star-outline" :style="menuItemStyle" />
              </template>
            </Icon>
            <span class="item-text">助教管理</span>
          </a-menu-item>

          <a-menu-item class="menu-item" key="Appes" @click="toPage('Appes')">
            <Icon>
              <template #component>
                <span class="mdi mdi-account-cowboy-hat-outline" :style="menuItemStyle" />
              </template>
            </Icon>
            <span class="item-text">教練管理</span>
          </a-menu-item>

          <a-menu-item class="menu-item" key="AppCourses" @click="toPage('AppCourses')">
            <Icon>
              <template #component>
                <block-outlined :style="menuItemStyle" />
              </template>
            </Icon>
            <span class="item-text">班級管理</span>
          </a-menu-item>

          <a-menu-item class="menu-item" key="AppForms" @click="toPage('AppForms')">
            <Icon>
              <template #component>
                <file-text-outlined :style="menuItemStyle" />
              </template>
            </Icon>
            <span class="item-text">表單管理</span>
          </a-menu-item>

          <a-menu-item class="menu-item" key="AppForum" @click="toPage('AppForum')">
            <Icon>
              <template #component>
                <span class="mdi mdi-book-open-variant" :style="menuItemStyle" />
              </template>
            </Icon>
            <span class="item-text">知識學堂</span>
          </a-menu-item>
        </template>
        <!-- Role: Admin -->

        <!-- Role: Assistant -->
        <template v-if="authStore.info.role == 3">
          <a-menu-item class="menu-item" key="AppCourses" @click="toPage('AppCourses')">
            <Icon>
              <template #component>
                <block-outlined :style="menuItemStyle" />
              </template>
            </Icon>
            <span class="item-text">班級管理</span>
          </a-menu-item>
          
          <a-menu-item class="menu-item" key="AppForum" @click="toPage('AppForum')">
            <Icon>
              <template #component>
                <span class="mdi mdi-book-open-variant" :style="menuItemStyle" />
              </template>
            </Icon>
            <span class="item-text">知識學堂</span>
          </a-menu-item>
        </template>
        <!-- Role: Assistant -->

        <!-- Role: Coach -->
        <template v-if="authStore.info.role == 2">
          <a-menu-item class="menu-item" key="AppCourses" @click="toPage('AppCourses')">
            <Icon>
              <template #component>
                <block-outlined :style="menuItemStyle" />
              </template>
            </Icon>
            <span class="item-text">班級管理</span>
          </a-menu-item>
          
          <a-menu-item class="menu-item" key="AppMaterials" @click="toPage('AppMaterials')">
            <Icon>
              <template #component>
                <block-outlined :style="menuItemStyle" />
              </template>
            </Icon>
            <span class="item-text">資源庫</span>
          </a-menu-item>
          
          <a-menu-item class="menu-item" key="AppForum" @click="toPage('AppForum')">
            <Icon>
              <template #component>
                <span class="mdi mdi-book-open-variant" :style="menuItemStyle" />
              </template>
            </Icon>
            <span class="item-text">知識學堂</span>
          </a-menu-item>
        </template>
        <!-- Role: Coach -->

        <a-sub-menu class="menu-item bottom" key="me">
          <template #icon>
            <Icon>
              <template #component>
                <a-avatar
                  :src="authStore.info.avatar!=null ? authStore.info.avatar.uri : require('@/assets/icons/avatar.png')"
                  :size="24"
                  :style="{ marginBottom: '5px', marginRight: '12px' }"
                />
              </template>
            </Icon>
          </template>
          <template #title>
            <span class="item-text">{{ authStore.info.name }}</span>
          </template>
          <a-menu-item key="mePage" @click="toMePage">個人資料</a-menu-item>
          <a-menu-item key="logout" @click="logout">登出</a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    
    <a-layout>
      <router-view />
    </a-layout>
  </a-layout>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { createDeviceDetector } from 'next-vue-device-detector'
import { message } from 'ant-design-vue'
import Icon, { BlockOutlined, FileTextOutlined } from '@ant-design/icons-vue'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import api from '@/apis'

export default defineComponent({
  components: {
    Icon,
    BlockOutlined,
    FileTextOutlined
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const device = createDeviceDetector();

    /* Store */
    const authStore = useAuthStore();
    const appStore = useAppStore();

    /* Mounted */
    onMounted(async () => {
      try {
        const response = await api.v1.auth.checkLogin();
        console.log(response.data.result);
        authStore.setInfo({
          info: response.data.result.info
        });
        console.log(authStore.info);
      } catch (error) {
        console.log(error);
        message.error('登入過期，請重新登入');
        router.push({ name: 'AuthLogin' });
      }
    });

    /* Menu */
    const selectedKeys = ref([route.name]);
    const menuItemStyle = {
      marginRight: '16px',
      fontSize: '1.3rem'
    }

    console.log(authStore.info);

    return {
      message,
      device,
      api,

      /* Store */
      authStore,
      appStore,
      
      /* Menu */
      menuItemStyle,
      selectedKeys
    }
  },
  methods: {
    /* Router */
    toPage(routerName) {
      this.$router.push({ name: routerName });
      if (this.device.mobile) this.appStore.collapsed = true;
    },
    toMePage() {
      this.selectedKeys = [];
      this.$router.push({ name: 'AppMePage' });
      if (this.device.mobile) this.appStore.collapsed = true;
    },

    /* api */
    async logout() {
      try {
        await this.api.v1.auth.logout();
      } catch (error) {
        console.log(error);
      } finally {
        message.success('登出成功');
        this.authStore.reset();
        this.$router.push({ name: 'AuthLogin' });
      }
    }
  }
})
</script>